.date-ranges-filter {
  display: flex;
  gap: 10px;

  &__filter {
    &-title {
      color: var(--Light-UI-Text-Regular-1, #222);
      font-family: Poppins;
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      margin-bottom: 2px;
    }
  }

  &__date-input {
    height: 2.5rem;
  }

  &__input {
    border: 1px solid #ddd;
    background: #fff;
    display: flex;
    align-items: center;
    position: relative;
  }

  &__calendar {
    position: absolute;
    left: 0.625rem;
    width: 1.875rem;
    height: 1.875rem;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
  }

  &__arrow {
    position: absolute;
    right: 0.5rem;
    width: 1.875rem;
    height: 1.875rem;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;

    &-svg {
      width: 100%;
      height: 100%;
    }
  }
}
