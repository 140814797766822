.back-block {
  height: 3.125rem;
  min-height: 3.125rem;
  width: 100%;
  display: flex;
  gap: 0.625rem;
  align-items: center;
  padding: 0 0.938rem;
  z-index: 2;
  border: none;
  outline: none;
  position: relative;
  background: #FFFFFF;
  font-family: 'Poppins', sans-serif;
  border-bottom: 1px solid var(--Light-UI-Stroke, #C4C6C9);


  &_needBottomBorder {
    border-bottom: 1px solid #2d3851;
  }

  &__arrow-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__arrow {
    width: 1.25rem;
    height: 1.25rem;
    display: flex;
    align-items: center;
    justify-content: center;

    [dir="rtl"] & {
      transform: rotate(180deg);
    }
  }

  &__content {
    max-width: 90%;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    color: var(--Light-UI-Text-Regular-1, #222);
    text-align: center;
    font-family: Poppins;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
}
