.users-list {
  display: flex;
  flex-direction: column;

  &__title {
    margin-bottom: 3.125rem;
    color: var(--Light-UI-Text-Regular, #000);
    font-family: Poppins;
    font-size: 2rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  &__filter {
    height: 3.125rem;
    width: fit-content;
    margin-bottom: 1.875rem;
    display: flex;
    gap: 0.625rem;
  }

  &__search {
    position: relative;
    width: 24.688rem;
    justify-content: center;
    display: flex;
  }

  &__search-input {
    width: 100%;
  }

  &__button {
    text-transform: uppercase;
  }

  &__subheader-data {
    width: 100%;
    display: flex;
    justify-content: center;
    align-self: center;
    padding: 0.625rem 0.9375rem;
  }

  &__subheader-data-item {
    min-width: 12.031rem;
    width: fit-content;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 0.625rem;
    border: 0.925px solid var(--Light-UI-Stroke, #C4C6C9);
    background: var(--Light-UI-Cards-1, #FFF);

    border-radius: 0.625rem;

    &:not(:last-child) {
      margin: 0 1px 0 0;
    }
  }
  &__subheader-data-item-nick {
    margin: 0 0.625rem 0 0;
    color: var(--Light-UI-Text-Regular-1, #222);
    font-family: Poppins;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  &__subheader-data-item-value {
    color: var(--Accent-Accent, #EA2326);
    font-family: Arial;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  &__list {
    width: 100%;
    display: flex;
    flex-direction: column;
    border-radius: 0.625rem;
    overflow: hidden;
  }

  &__item {
    width: 100%;
    height: 2.563rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 1.563rem;
    border-radius: 0.625rem;
    border: 1px solid var(--Light-UI-Stroke, #C4C6C9);
    background: var(--Light-UI-Cards-1, #FFF);
    margin: 0 0 0.313rem 0;
  }
  &__item-text {
    max-width: 75%;
    overflow: auto;
    white-space: nowrap;
    margin: 0 0.625rem 0 0;
    color: var(--Light-UI-Text-Regular-1, #222);
    font-family: Poppins;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  &__item-balance {
    margin: 0 1.25rem 0 auto;
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 1rem;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #EA2326;
  }
  &__item-arrow {
    width: 1rem;
    height: 1rem;
    display: flex;

    [dir='rtl'] & {
      transform: rotate(180deg);
    }
  }

  &__paginator {
    margin-top: 1.875rem;
    display: flex;
    justify-content: center;
  }
}
