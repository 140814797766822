.withdrawal-history-item {
  padding: 0.625rem 1.25rem;
  border-radius: 0.625rem;
  border: 1px solid var(--Light-UI-Stroke, #C4C6C9);
  background: var(--Light-UI-Cards-1, #FFF);
  display: grid;
  grid-template-columns: repeat(6, minmax(0, 1fr));
  gap: 0.625rem;

  font-size: 0.857rem;
  font-weight: 700;
  color: #000;

  &__top, &__bottom {
    display: flex;
    justify-content: space-between;
  }

  &__bottom {
    display: grid;
    grid-template-columns: 50% 50%;
    gap: 0.625rem;
  }

  &__item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    &-text {
      color: var(--Light-UI-Text-Additional-1, #828282);
      font-variant-numeric: lining-nums proportional-nums;
      font-family: Poppins;
      font-size: 0.75rem;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }

    &-value {
      overflow: hidden;
      color: var(--Light-UI-Text-Regular-1, #222);
      font-variant-numeric: lining-nums proportional-nums;
      text-overflow: ellipsis;
      font-family: Poppins;
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 600;
      line-height: normal;

      &_status {
        &_1 {
          color: var(--Status-Good, #1E9E5C);
          text-align: center;
          font-family: Arial;
          font-size: 0.875rem;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
        }

        &_2 {
          color: var(--Status-Bad, #CE000C);
          text-align: center;
          font-family: Arial;
          font-size: 0.875rem;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
        }
      }

      &_red {
        span {
          color: var(--Accent-Accent, #EA2326);
          text-align: center;
          font-family: Poppins;
          font-size: 0.875rem;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
        }

      }
    }
  }

  &__buttons {
    display: flex;
    gap: 0.625rem;
    justify-content: flex-end;
  }

  &__button {
    height: 1.938rem;
    width: 100%;

    &-text {
      color: var(--Light-UI-Text-Regular, #FFF);
      font-family: Arial;
      font-size: 11px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;

      &_red {
        color: #EA2326;
      }
    }
  }
}
