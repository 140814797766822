.active-requests-desktop {
  flex-grow: 2;
  display: flex;
  flex-direction: column;
  gap: 0.313rem;

  &__paginator {
    margin-top: 1.875rem;
    display: flex;
    justify-content: center;
  }

  &__title {
    color: var(--Light-UI-Text-Regular, #000);
    font-family: Poppins;
    font-size: 2rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 1.875rem;
  }
}
