.create-user {
  width: 100%;
  height: 100%;
  &__title {
    color: var(--Light-UI-Text-Regular, #000);
    font-family: Poppins;
    font-size: 2rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  &__sub-title {
    margin-bottom: 2.5rem;
    font-weight: 700;
    font-size: 1.375rem;
    text-align: center;
  }

  &__content {
    margin: 6.25rem auto 0;
    width: 23.125rem;
  }

  &__field {
    width: 100%;
    margin-bottom: 0.8rem;
  }
  &__field-title {
    margin: 0 0 2px 0;
    color: var(--Light-UI-Text-Regular-1, #222);
    font-family: Poppins;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  &__field-input {
    width: 100%;
    height: 2.5rem;
  }

  &__button {
    margin-top: 2.5rem;
    height: 2.5rem;
    width: 100%;
  }
}
