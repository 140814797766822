.transaction-history {
  &__title {
    margin-bottom: 1.875rem;
    color: var(--Light-UI-Text-Regular, #000);
    font-family: Poppins;
    font-size: 2rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  &__content {
    width: 100%;
    display: flex;
    gap: 0.625rem;
    align-items: flex-end;
    margin-bottom: 1.875rem;
  }

  &__search {
    height: 2.5rem;
  }

  &__fields-title {
    margin: 0 0 0.4375rem 0;
    font-weight: 700;
    font-size: 0.75rem;
    line-height: 0.875rem;
    color: #797b83;
  }
  &__fields {
    min-width: 32.46%;
    width: fit-content;
    display: grid;
    grid-template-columns: repeat(3, 16.25rem);
    grid-gap: 2px 0.625rem;
  }
  &__field-title {
    color: var(--Light-UI-Text-Regular-1, #222);
    font-family: Poppins;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 2px;
  }
  &__field-input {
    width: 100%;
    height: 2.5rem;
  }

  &__button {
    width: 100%;
    height: 2.5rem;
  }
  &__button-text {
    font-weight: 600;
    font-size: 0.875rem;
    text-align: center;
    text-transform: uppercase;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #111522;
  }

  &__result-wrapper {
    border: 1px solid #EA2326;
    border-radius: 0.625rem;
    overflow: hidden;
  }

  &__result-header {
    background: linear-gradient(180deg, #c09a37 0%, #f6ea86 51.04%, #cfa946 100%);
    height: 2.8125rem;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    align-items: center;
    margin: 0 0 1px 0;
    padding: 0 1.25rem;
  }

  &__row {
    height: 3.688rem;
    display: grid;
    grid-template-columns: repeat(3, 1fr) auto;
    align-items: center;
    margin: 0 0 0.313rem 0;
    padding: 0.625rem 3.125rem;
    border-radius: 10px;
    border: 1px solid var(--Light-UI-Stroke, #C4C6C9);
  }

  &__td {
    overflow: auto;
    width: 100%;
    font-weight: 600;
    font-size: 0.75rem;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #fff;

    &-title {
      color: var(--Light-UI-Text-Additional-1, #828282);
      font-family: Poppins;
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }

    &-value {
      color: var(--Light-UI-Text-Regular-1, #222);
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }


    &::-webkit-scrollbar {
      height: 0;
    }

    &_accented {
      font-weight: 800;
      font-size: 1.375rem;
      color: #EA2326;
    }
  }

  &__th {
    overflow: auto;
    width: 100%;
    font-weight: 600;
    font-size: 0.75rem;
    line-height: 0.875rem;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #011b11;

    &:not(:first-child) {
      text-align: center;
    }

    &::-webkit-scrollbar {
      height: 0;
    }
  }

  &__totals {
    width: 100%;
    height: 2.1875rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 2.5rem;
    background: #2e323e;
  }
  &__totals-withdrawal,
  &__totals-topUp {
    display: flex;
    align-items: center;
  }
  &__totals-withdrawal-title,
  &__totals-withdrawal-value,
  &__totals-topUp-title,
  &__totals-topUp-value {
    font-weight: 600;
    font-size: 0.75rem;
    line-height: 0.875rem;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #ffffff;
  }
  &__totals-withdrawal-value,
  &__totals-topUp-value {
    color: #ffb401;
  }
  &__totals-withdrawal-title,
  &__totals-topUp-title {
    margin: 0 0.625rem 0 0;
  }
}
