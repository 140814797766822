.menu-item {
  width: 100%;
  display: flex;
  align-items: center;

  &__name {
    margin-bottom: 0.8125rem;
    font-weight: 700;
    font-size: 0.875rem;
    line-height: 0.875rem;
    color: #EA2326;
    font-weight: 700;
  }

  &__sub-list {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    &:not(:last-child) {
      margin-bottom: 0.75rem;
    }
  }

  &__element {
    padding: 0.313rem 0.625rem 0.313rem 1.25rem;
    width: 100%;
    border: 1px solid var(--Light-UI-Stroke, #C4C6C9);
    background: var(--Light-UI-Cards-1, #FFF);
    border-radius: 0.625rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #222222;

    &:not(:last-child) {
      margin: 0 0 0.625rem 0;
    }

    &_active {
      background: #EA2326;
      border-color: transparent;

      span {
        color: #ffffff;
      }

    }
  }
  &__element-name {
    color: var(--Light-UI-Text-Regular-1, #222);
    font-family: Poppins;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  &__element-indicator {
    position: relative;
    width: 1.25rem;
    min-width: 1.25rem;
    height: 1.25rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 1.25rem 0 auto;
  }
  &__element-indicator-background {
    position: absolute;
    width: 1.25rem;
    min-width: 1.25rem;
    height: 1.25rem;
    background-color: red;
    border-radius: 50%;
    transform: scale(1);
    animation: 1s infinite alternate indicate;
  }
  &__element-indicator-count {
    z-index: 1;
    font-size: 0.75rem;
    line-height: 0.75rem;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: white;
  }
  &__element-arrow {
    width: 1.875rem;
    height: 1.875rem;
    display: flex;

    &_active {
      * {
        fill: #ffffff;
      }
    }

    [dir='rtl'] & {
      transform: rotate(180deg);
    }
  }

  @keyframes indicate {
    from {
      transform: scale(1);
    }

    to {
      transform: scale(1.2);
    }
  }
}
