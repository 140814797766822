.total {
  &__title {
    margin-bottom: 1.875rem;
    color: var(--Light-UI-Text-Regular, #000);
    font-family: Poppins;
    font-size: 2rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  &__content {
    width: 100%;
  }

  &__fields-title {
    margin: 0 0 0.4375rem 0;
    font-weight: 600;
    font-size: 0.875rem;
  }

  &__fields {
    min-width: 32.46%;
    width: fit-content;
    display: grid;
    grid-template-columns: repeat(3, 16.25rem);
    grid-gap: 0.3125rem 1.25rem;
    margin: 0 0 1.875rem 0;
  }
  &__field-title {
    color: var(--Light-UI-Text-Regular-1, #222);
    font-family: Poppins;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 2px;
  }

  &__field-input {
    width: 100%;
    height: 2.5rem;
  }

  &__button {
    width: 100%;
    height: 2.5rem;
  }
  &__button-text {
    font-weight: 600;
    font-size: 0.875rem;
    text-align: center;
    text-transform: uppercase;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #111522;
  }

  &__result {
    width: 100%;
    overflow: hidden;
  }
  &__result-header {
    height: 2.1875rem;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    align-items: center;
    margin: 0 0 1px 0;
    padding: 0 1.25rem;
    background: linear-gradient(180deg, #c09a37 0%, #f6ea86 51.04%, #cfa946 100%);
    box-shadow: 0px 5px 10px rgba(46, 66, 98, 0.05);
  }

  &__row {
    height: 3.688rem;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr min-content;
    align-items: center;
    margin: 0 0 0.313rem 0;
    padding: 0.625rem 3.125rem;
    border-radius: 10px;
    border: 1px solid var(--Light-UI-Stroke, #C4C6C9);
  }

  &__th {
    overflow: auto;
    width: 100%;
    font-weight: 600;
    font-size: 0.75rem;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #011b11;

    &:not(:first-child) {
      text-align: center;
    }

    &::-webkit-scrollbar {
      height: 0;
    }

    &_accented {
      font-weight: 800;
      font-size: 1.375rem;
      color: #EA2326;
    }
  }

  &__td {
    overflow: auto;
    width: 100%;
    font-weight: 600;
    font-size: 0.75rem;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #fff;

    &-title {
      color: var(--Light-UI-Text-Additional-1, #828282);
      font-family: Poppins;
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      text-align: start;
    }

    &-value {
      color: var(--Light-UI-Text-Regular-1, #222);
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      text-align: start;
    }


    &:not(:first-child) {
      text-align: center;
    }

    &::-webkit-scrollbar {
      height: 0;
    }

    &_accented {
      font-weight: 800;
      font-size: 1.375rem;
      color: #EA2326;
    }
  }
}
