.tree-row {
  z-index: 2;
  position: relative;
  white-space: nowrap;
  color: #ffffff;

  &__ladder {
    position: absolute;
    top: 0;
    bottom: calc(0% + 4.5rem / 2);
    left: 1rem;
    width: 1.375rem;
    display: flex;
    align-items: center;
  }
  &__ladder-vertical {
    position: absolute;
    top: 0;
    width: 2px;
    height: 100%;
    background: #EA2326;
  }
  &__ladder-horizontal1 {
    width: 0.875rem;
    height: 2px;
    background: #EA2326;
    top: 50%;
    position: absolute;
    left: -0.875rem;
  }
  &__ladder-horizontal2 {
    width: 3.125rem;
    height: 2px;
    background: #EA2326;
    top: 100%;
    position: absolute;
    left: 0;
  }

  &__parent {
    z-index: 2;
    position: relative;
    width: 100%;
    height: 4.5rem;
    display: grid;
    grid-template-columns: 2.5rem 1fr 1fr 1fr;
    grid-gap: 0 0.3125rem;
    align-items: center;
    justify-content: space-between;
    padding: 0.625rem;
    margin-bottom: 1px;
    border-radius: 0.938rem;
    background: var(--Light-UI-Cards-2, #F1F3F7);

    &_withoutSubusers {
      grid-template-columns: repeat(3, 1fr);
      padding-left: 0.625rem;
    }
  }
  &_isPlayer &__parent {
    grid-template-columns: 1fr 1fr 1fr;
  }

  &__arrow {
    width: 1.875rem;
    min-width: 100%;
    height: 1.875rem;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.25s;
    transform: rotate(180deg);

    &_opened {
      transform: rotate(0deg);
    }
    &_disabled {
      opacity: 0;
    }
  }
  &_banned &__arrow {
    opacity: 0.3;
  }

  &__data1 {
    width: 100%;
    min-width: 100%;
    display: flex;
    flex-direction: column;
  }
  &_banned &__data1 {
    opacity: 0.3;
  }
  &__data1-role {
    color: var(--Light-UI-Text-Additional-1, #828282);
    font-variant-numeric: lining-nums proportional-nums;
    font-family: 'Roboto';
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  &__data1-username {
    min-width: 20rem;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    color: var(--Light-UI-Text-Regular, #000);
    font-variant-numeric: lining-nums proportional-nums;
    font-family: Roboto;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;

    &::-webkit-scrollbar {
      height: 0;
    }
  }
  &__data1-players {
    display: flex;
    align-items: end;
  }
  &__data1-players-title {
    margin: 0.3125rem 0.3125rem 0 0;
    color: var(--Light-UI-Text-Additional-1, #828282);
    font-variant-numeric: lining-nums proportional-nums;
    font-family: Roboto;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  &__data1-players-value {
    color: var(--Light-UI-Text-Regular, #000);
    font-variant-numeric: lining-nums proportional-nums;
    font-family: Roboto;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  &__data21 {
    justify-self: center;
    align-self: center;
    overflow: auto;
    display: flex;
    flex-direction: column;

    &::-webkit-scrollbar {
      height: 0;
    }
  }
  &__data22 {
    align-self: end;
    width: 100%;
    min-width: 100%;
    overflow: auto;
    display: flex;
    flex-direction: column;

    &::-webkit-scrollbar {
      height: 0;
    }
  }
  &_banned &__data2 {
    opacity: 0.3;
  }
  &__balance2 {
    display: flex;
    flex-direction: column;
  }
  &__balance2-title {
    font-weight: 600;
    font-size: 0.75rem;
    line-height: 0.875rem;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #797b83;
  }
  &__balance2-value {
    color: var(--Light-UI-Text-Regular, #000);
    font-variant-numeric: lining-nums proportional-nums;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  &__subusers {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  &__subusers-title {
    margin: 0 0.3125rem 0 0;
    color: var(--Light-UI-Text-Additional-1, #828282);
    font-variant-numeric: lining-nums proportional-nums;
    font-family: Roboto;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  &__subusers-value {
    color: var(--Light-UI-Text-Regular, #000);
    font-variant-numeric: lining-nums proportional-nums;
    font-family: Roboto;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  &__data3 {
    justify-self: flex-end;
    width: fit-content;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
  &__ban {
    width: 4.563rem;
    height: 1.3125rem;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 0.875rem 0 0.625rem;
    cursor: pointer;
    border-radius: 0.625rem;
    border: 1px solid var(--Accent-Accent, #EA2326);
  }
  &__ban-icon {
    width: 0.875rem;
    min-width: 0.875rem;
    height: 0.875rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 0.625rem 0 0;
  }
  &__ban-text {
    color: var(--Light-UI-Text-Regular, #000);
    text-align: center;
    font-family: Roboto;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
  }
  &__balance1 {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin: 0.75rem 0 0 0;
  }
  &_banned &__balance1 {
    opacity: 0.3;
  }
  &__balance1-title {
    margin: 0 0.3125rem 0 0;
    color: var(--Light-UI-Text-Additional-1, #828282);
    font-variant-numeric: lining-nums proportional-nums;
    font-family: Roboto;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  &__balance1-value {
    color: var(--Light-UI-Text-Regular, #000);
    font-variant-numeric: lining-nums proportional-nums;
    font-family: Roboto;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  &__childrens {
    position: relative;
    padding-left: 1.875rem;
  }
}
