.header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: var(--Light-UI-Cards-1, #FFF);
  padding: 1.031rem 3.125rem;
  font-weight: 700;

  &__wrapper {
    max-width: 70%;
    display: flex;
    align-items: center;
  }

  &__home {
    margin-right: 0.9375rem;
    display: block;
    width: 1.875rem;
    height: 1.875rem;
  }

  &__icon {
    margin-right: 0.625rem;
    display: block;
    width: 1.5rem;
    height: 1.5rem;

    &_type_logout {
      margin-left: auto;
      margin-right: 0;
    }
  }

  &__title {
    margin-left: 0.625rem;
    width: 100%;
    font-size: 1.25rem;
  }

  &__user {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__block {
    flex-shrink: 0;
    min-width: 11.875rem;
    padding: 0.625rem 1.25rem;
    display: flex;
    flex-direction: column;
    background: var(--Light-UI-Cards-2, #F1F3F7);
    border-radius: 0.625rem;

    color: var(--Accent-Accent, #EA2326);
    font-family: Poppins;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: capitalize;

    span {
      color: var(--Light-UI-Text-Regular-1, #222);
      font-family: Poppins;
      font-size: 1.125rem;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      text-transform: capitalize;
      margin-right: 0.625rem;
    }

    &_type_logout {
      margin-left: 0.625rem;
      min-width: 15.625rem;
    }
  }

  &__currency {
    color: var(--Light-UI-Text-Regular-1, #222);
    font-family: Poppins;
    font-size: 0.813rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: capitalize;
    margin: 3px 0 0 0.313rem;
  }

  &__logout {
    display: flex;
    border-radius: 0.625rem;
    background: var(--Light-UI-Cards-2, #F1F3F7);
    padding: 0.625rem 1.25rem;
    cursor: pointer;

    &-icon {
      width: 1.625rem;
      height: 1.625rem;
      margin-right: 0.625rem;
    }

    &-text {
      color: var(--Light-UI-Text-Regular-1, #222);
      font-family: Poppins;
      font-size: 1.125rem;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      text-transform: capitalize;
    }
  }

  &__row {
    display: flex;
    align-items: baseline;
  }

  &__id {
    &-label {
      margin-right: 0.3125rem;
      color: #222;
      font-size: 0.875rem;
      font-weight: 600;
    }

    &-value {
      color: #EA2326;
      font-size: 0.875rem;
      font-weight: 700;
    }
  }
}
