.user-edit {
  &__title {
    margin-bottom: 3.125rem;
    color: var(--Light-UI-Text-Regular, #000);
    font-family: Poppins;
    font-size: 2rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    display: flex;
    align-items: center;
  }

  &__arrow {
    margin: 0 1.25rem;
    display: block;
    width: 1.063rem;
    height: 1.063rem;
  }

  &__sub-title {
    margin-bottom: 2.25rem;
    font-weight: 700;
    font-size: 1.375rem;
    text-align: center;
  }

  &__content {
    display: flex;
    justify-content: center;
  }

  &__block {
    width: 23.125rem;
  }

  &__inputs {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  &__field {
    width: 100%;
    display: flex;
    flex-direction: column;

    &:not(:last-child) {
      margin: 0 0 0.313rem 0;
    }
  }
  &__field-title {
    margin-bottom: 2px;
    color: var(--Light-UI-Text-Regular-1, #222);
    font-family: Poppins;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  &__field-input {
    width: 100%;
    height: 2.8125rem;
  }

  &__payment-button {
    width: 100%;
    height: 2.5rem;
    margin: 2.5rem 0;
  }
  &__payment-button-text {
    font-weight: 600;
    font-size: 0.875rem;
    text-align: center;
    text-transform: uppercase;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #111522;
  }

  &__reset-button {
    width: 100%;
    height: 2.5rem;
    margin-bottom: 1.25rem;
  }
  &__reset-button-text {
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 1rem;
    text-align: center;
  }

  &__buttons {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 0 0.625rem;
  }

  &__ban-button,
  &__unban-button {
    width: 100%;
    height: 2.5rem;
  }
  &__ban-button-text {
    font-weight: 600;
    font-size: 0.875rem;
    text-align: center;
    text-transform: uppercase;
    font-feature-settings: 'pnum' on, 'lnum' on;
  }
  &__unban-button-text {
    color: var(--Light-UI-Text-Regular-1, #222);
    text-align: center;
    font-family: Poppins;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: uppercase;
  }
}
