.tree {
  width: 100%;
  overflow-x: auto;
  padding: 0 0 6.25rem 0;
  color: #dddddd;

  &__title {
    margin-bottom: 1.875rem;
    color: var(--Light-UI-Text-Regular, #000);
    font-family: Poppins;
    font-size: 2rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
}
