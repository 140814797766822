.withdrawal-history {
  flex-grow: 2;
  display: flex;
  flex-direction: column;

  &__title {
    color: var(--Light-UI-Text-Regular, #000);
    font-family: Poppins;
    font-size: 2rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 1.875rem;
  }

  &__top-filters {
    display: flex;
    gap: 0.625rem;
  }

  &__bottom-filters {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 0.625rem;
    align-items: flex-end;
  }

  &__filters {
    display: flex;
    flex-direction: column;
    gap: 1.875rem;
    margin-bottom: 1.875rem;
  }

  &__items {
    display: flex;
    flex-direction: column;
    gap: 0.313rem;
  }

  &__paginator {
    margin-top: 1.875rem;
    display: flex;
    justify-content: center;
  }

  &__row {
    display: flex;
    gap: 0.714rem;
  }

  &__label {
    display: flex;
    align-items: center;
    gap: 0.625rem;
    cursor: pointer;

    color: var(--Light-UI-Text-Regular, #000);
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  &__search-block {
    height: 2.5rem;

    /* Убираем стрелочки увеличения/уменьшения в браузере Chrome, Safari и Opera */
    input[type=number]::-webkit-inner-spin-button,
    input[type=number]::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Убираем стрелочки увеличения/уменьшения в браузере Firefox */
    input[type=number] {
      -moz-appearance: textfield;
    }
  }

  &__checkbox {
    width: 1.429rem;
    height: 1.429rem;
  }

  &__dates {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 2px 0.714rem;
  }

  &__button {
    height: 2.5rem;
  }

  &__text {
    color: var(--Light-UI-Text-Regular-1, #222);
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;

    &_fontSize {
      &_14 {
        color: var(--Light-UI-Text-Regular-1, #222);
        font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
    }
  }

  &__status-filter-block {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    > div {
      margin: 0.5rem 0.844rem 0.5rem 0;
    }
  }
}