.menu {
  padding: 1.875rem 1.25rem 3.125rem;

  &__title {
    background: #011b11;
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    padding: 0 0.625rem;
    font-weight: 700;
    font-size: 0.75rem;
    line-height: 0.875rem;
    color: white;
  }

  &__list {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 5.625rem;
  }

  &__logo {
    width: 12.313rem;
    height: 1.375rem;
    margin: 0 auto 5.625rem;
    display: flex;
  }

  &__item {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 0.313rem 0.625rem 0.313rem 1.25rem;
    border-radius: 0.625rem;
    border: 1px solid var(--Light-UI-Stroke, #C4C6C9);
    background: #FFF;

    &:not(:last-child) {
      margin-bottom: 0.625rem;
    }
  }

  &__item-img {
    width: 1.5rem;
    height: 1.5rem;
    display: flex;
    margin-right: 0.875rem;

    * {
      fill: #EA2326;
    }
  }

  &__item-text {
    color: var(--Light-UI-Text-Regular-1, #222);
    font-family: Poppins;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  &__element-arrow {
    width: 1.875rem;
    height: 1.875rem;
    display: flex;
    margin: 0 0 0 auto;

    [dir='rtl'] & {
      transform: rotate(180deg);
    }
  }
}
