@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');


.app {
  width: 100%;
  height: 100%;
  background: #F1F3F7;
  color: #fff;
  display: flex;
  flex-direction: column;
  overflow-y: auto;

  &__main {
    height: 100%;
    display: flex;
  }

  &__menu {
    min-width: 21.875rem;
    border-right: 1px solid var(--Light-UI-Stroke, #C4C6C9);
    background: var(--Light-UI-Background, #F1F3F7);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &__container {
    margin: 3.438rem 3.125rem;
    padding: 1.875rem 3.125rem;
    border-radius: 1.25rem;
    background: var(--Light-UI-Cards-1, #FFF);
    flex: 1;
  }

  &__content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }

  &__welcome {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    font-size: 1.25rem;
    color: #fff;
  }

  &__logo {
    margin-bottom: 0.5rem;
    display: block;
    width: 33.4375rem;
    height: 9.8125rem;
    filter: grayscale(1);
  }

  &__welcome-title {
    margin-bottom: 1.25rem;
    font-size: 1.875rem;
  }
}

@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@400;600;700;800&display=swap');

#root,
html,
body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: 'Poppins', sans-serif;
  background: #000;
}
*,
*::after,
*::before {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  text-decoration: none;
  list-style: none;
  color: inherit;
}

html {
  font-size: 1rem;
}

ul,
ol,
li,
p,
a,
h1,
h2,
h3,
h4,
h5,
h6 {
  padding: 0;
  margin: 0;
  text-decoration: none;
  list-style: none;
  color: inherit;
}

@mixin mq-font-by-max-width($fontSize, $maxWidth) {
  @media only screen and (max-width: $maxWidth) {
    html {
      font-size: $fontSize;
    }
  }
}

@include mq-font-by-max-width(18px, 2600px);
@include mq-font-by-max-width(17px, 2300px);
@include mq-font-by-max-width(16px, 1920px);
@include mq-font-by-max-width(15px, 1750px);
@include mq-font-by-max-width(14px, 1600px);
@include mq-font-by-max-width(13px, 1440px);
@include mq-font-by-max-width(13px, 1366px);
