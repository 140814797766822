.language-dropdown-selector-mobile {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background: transparent;
  gap: 0.625rem;
  cursor: pointer;

  &__current-lang,
  &__lang {
    display: flex;
    align-items: center;
  }

  &__current-item {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    border-radius: 0.625rem;
    border: 1px solid var(--Light-UI-Stroke, #C4C6C9);
    padding: 0.313rem 0.625rem;
    background: #FFF;

    //&_isOpen {
    //  border-radius: 0.313rem 0.313rem 0 0;
    //}

    &_menuLayout {
      padding: 0.313rem 0.625rem 0.313rem 1.25rem;
    }
  }
  &__current-lang-name {
    font-family: Poppins;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    color: #222222;
    text-transform: capitalize;
  }
  &__current-flag {
    justify-self: flex-end;
    width: 1.5rem;
    height: 1.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-right: 0.625rem;
  }
  &__arrow {
    justify-self: flex-end;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 1.875rem;
    height: 1.875rem;

    &_menuLayout {
      transform: rotate(-90deg);
    }

    &_isOpen {
      transform: rotate(-180deg);
    }

    &_menuLayout &_isOpen {
      transform: rotate(90deg);
    }
  }

  &__items {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1.375rem;
    justify-content: space-between;
    box-sizing: border-box;
    overflow: hidden;
    z-index: 999;
    border-radius: 0.625rem;
    border: 1px solid var(--Light-UI-Stroke, #C4C6C9);
    background: var(--Light-UI-Cards-1, #FFF);
    padding: 0.938rem;
  }
  &__item {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    box-sizing: border-box;
    padding: 0.313rem 0.625rem;

    &_active {
      border-radius: 0.625rem;
      border: 1px solid var(--Accent-Accent, #EA2326);
    }
  }
  &__lang-name {
    font-size: 0.875rem;
    color: var(--Light-UI-Text-Regular-1, #222);
    text-align: center;
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: uppercase;
  }
  &__flag {
    align-self: center;
    width: 1.5rem;
    height: 1.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-right: 0.625rem;
  }
  &__item:hover &__flag {
    opacity: 1;
  }

  [dir="rtl"] & {
    &__arrow {
      transform: rotate(180deg);
      &_isOpen {
        transform: rotate(90deg);
      }
    }
  }
}
