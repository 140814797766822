.change-pas-desktop {
  display: flex;
  align-items: center;
  justify-content: center;

  &__content {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  &__main {
    width: 23.125rem;
    margin: 6.25rem auto 0;
  }

  &__title {
    color: var(--Light-UI-Text-Regular, #000);
    font-family: Poppins;
    font-size: 2rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  &__form {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
  }

  &__item {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;

    &-desc {
      color: var(--Accent-Accent, #EA2326);
      font-family: Poppins;
      font-size: 0.75rem;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      margin-top: 0.625rem;
    }
  }
  &__item-title {
    width: 100%;
    margin: 0 0 2px 0;
    color: var(--Light-UI-Text-Regular-1, #222);
    font-family: Poppins;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  &__item-field {
    widows: 100%;
    height: 40px;
  }

  &__button {
    margin-top: 2.5rem;
    width: 100%;
    height: 2.5rem;
  }
  &__button-text {
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    text-transform: uppercase;
    color: #111522;
  }
}
