.locale {
  height: 100%;
  min-width: 44.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4.875rem;

  &__content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__title {
    margin-bottom: 1.25rem;
    font-weight: 500;
    font-size: 1.875rem;
    color: #797b83;
    text-align: center;
  }

  &__item {
    width: 100%;
    height: 3.125rem;
    display: flex;
    align-items: center;
    padding-left: 0.625rem;
    margin: 0 0 0.625rem 0;
    border: 1px solid #fff;
    border-radius: 0.3125rem;
    cursor: pointer;
    color: #fff;
  }
  &__item_active {
    background: #EA2326;
    border: none;
    color: #011b11;
  }

  &__item-flag {
    width: 1.4rem;
    margin-right: 0.5rem;
  }
  &__item-text {
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 1rem;
  }

  &__bottom {
    margin-top: 17.375rem;
    width: 20rem;
  }
  &__button {
    width: 100%;
    height: 2.5rem;
  }
  &__button-text {
    font-weight: 700;
    font-size: 0.875rem;
    line-height: 1rem;
    text-align: center;
    text-transform: uppercase;
    color: #000000;
  }
}
