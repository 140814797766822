.input-search-mobile {
  user-select: none;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 0.938rem;
  background: transparent;
  border-radius: 0.625rem;
  border: 1px solid var(--Light-UI-Stroke, #C4C6C9);
  background: var(--Light-UI-Cards-1, #FFF);
  position: relative;
  overflow: hidden;

  &__input {
    z-index: 2;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    outline: none;
    border: none;
    color: var(--Light-UI-Text-Regular-1, #222);
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    font-family: 'Poppins', sans-serif;
    -webkit-appearance: none;
    background: transparent;

    &::placeholder {
      color: var(--Light-UI-Text-Additional-1, #828282);
      font-family: Poppins;
      font-size: 0.938rem;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
    &::-webkit-search-cancel-button {
      opacity: 0;
      width: 0 !important;
      min-height: 0 !important;
      /* cursor: pointer;
      margin: 0 0.3125rem 0 0.625rem !important;
      min-width: 2.5rem !important;
      min-height: 2.5rem !important; */
    }
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus#{&}:-webkit-autofill,
    &:-webkit-autofill::first-line,
    &::-internal-input-seggested {
      /* font: 1.25rem 'Product Sans', sans-serif !important; */ // рофланебало от разрабов хрома - захардкодили стили фонта ради безопасности - https://bugs.chromium.org/p/chromium/issues/detail?id=953689
      color: #797b83;
      -webkit-text-fill-color: #797b83;
      -webkit-box-shadow: 0 0 0px 1000px transparent inset;
      transition: background-color 5000s ease-in-out 0s;
      background: -webkit-linear-gradient(
        top,
        rgba(255, 255, 255, 0) 0%,
        rgba(0, 174, 255, 0.04) 50%,
        rgba(255, 255, 255, 0) 51%,
        rgba(0, 174, 255, 0.03) 100%
      );
    }
  }

  &__cross {
    width: 0.9375rem;
    height: 0.9375rem;
    display: flex;
  }

  &__clear-input {
    position: absolute;
    right: 0.7rem;
    width: 1.2rem;
    height: 1.2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 3;
  }

  &__search-icon {
    width: 1.375rem;
    height: 1.375rem;
    display: flex;
    margin: 0 0.938rem 0 0;


    span {
      width: 1.375rem;
      height: 1.375rem;
    }
  }
}
