.paginator-dropdown-selector-mobile {
  width: fit-content;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  user-select: none;

  &__selected-item {
    margin-bottom: 0.3125rem;
    cursor: pointer;
    height: 100%;
    position: relative;
    display: grid;
    grid-template-columns: auto 1.875rem;
    grid-auto-rows: 100%;
    gap: 0.3125rem;
  }

  &__selected-item-value {
    height: 100%;
    min-width: 4.063rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--Light-UI-Text-Regular, #000);
    font-family: Poppins;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 900;
    line-height: 18px; /* 128.571% */
    text-align: center;
    /* border-bottom: 1px solid #a6a8ab; */
    border-radius: 10px;
    border: 1px solid var(--Light-UI-Stroke, #C4C6C9);
  }
  &__selected-item-arrow {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* transition: transform 0.3s ease; */

    &-svg {
      width: 1.875rem;
      min-width: 1.875rem;
      height: 1.875rem;
    }
  }
  &_open &__selected-item-arrow {
    transform: scaleY(-1);
  }

  &__items {
    box-sizing: border-box;
    width: 100%;
    max-height: 9rem;
    z-index: 2;
    position: absolute;
    top: 100%;
    overflow: auto;
    border: 1px solid #C4C6C9;
    // border-top: none;
    background-color: #fff;
    border-radius: 0.625rem;

    &::-webkit-scrollbar {
      width: 0.1875rem;
      height: 90%;
      scroll-margin-right: 0.5rem;
    }
    &::-webkit-scrollbar-thumb {
      cursor: pointer;
      width: 0.1875rem;
      background: var(--accent-accent-2, #E41420);
    }
  }
  &__item {
    box-sizing: border-box;
    min-height: 2.73rem;
    padding: 0.357rem;
    position: relative;
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  &__item-value {
    box-sizing: border-box;
    min-width: 2.875rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 900;
    font-size: 1rem;
    color: #000;
  }
}
